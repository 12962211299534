import React from "react"

export const ANIMATED_COUNTERS = [
  {
    id: 1,
    text: <>Years in the IT market (of activity)</>,
    start: 0,
    end: 10,
    delay: 0,
    suffix: "+",
  },
  {
    id: 2,
    text: <>Projects we built</>,
    start: 0,
    end: 85,
    delay: 0,
    suffix: "+",
  },
  {
    id: 3,
    text: <>Cities worldwide</>,
    start: 0,
    end: 35,
    delay: 0,
  },
  {
    id: 4,
    text: <>Industries Expertise</>,
    start: 0,
    end: 19,
    delay: 0,
  },
  {
    id: 5,
    text: <>Multi-talented and experienced professionals on board</>,
    start: 0,
    end: 120,
    delay: 0,
    suffix: "+",
  },
]
